import React, { ReactElement } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { colors } from './colors';
import { spacings } from './spacing';
import { fontSizes } from './fonts';
const depths = {
  confirmationTakeover: '2',
};

const theme = {
  ...fontSizes,
  ...colors,
  ...spacings,
  ...depths,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Theme(props: any): ReactElement {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}

export const Bold = styled.span`
  font-weight: bold;
`;

export const ErrorMessage = styled.p`
  color: ${(props): string => props.theme.warningRed}
  font-weight: bold;
  font-size: 0.8rem;
  text-align: left;
  line-height: 1rem;
`;

export const Title = styled.h1`
  font-size: 2.7rem;
  font-weight: bold;
  margin-bottom: 30px;
`;

export const Heading = styled.h1`
  font-size: ${theme.xl};
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const Heading2 = styled.h2`
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const Heading3 = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const HorizontalRule = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid ${theme.grey};
  margin-bottom: 76px;
  margin-left: 50px;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  margin-top: 16px;
`;

export const StyledTableHeader = styled.th`
  text-align: left;
  font-weight: bold;
  padding: 8px;
`;

export const StyledTableCell = styled.td`
  padding: 8px;
`;
